<template>
  <section class="container">
    <div>
      <h1>
        {{ $t("supplier.addEdit.createSupplier") }}
      </h1>
    </div>
    <div>
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="signup-emailForm">
          <Supplier v-model="supplier"></Supplier>
          <BaseActionButton
            v-bind:clicked="clicked"
            v-bind:disabled="clicked"
            type="submit"
            id="signupButton"
          >
            {{ $t("global.create") }}
          </BaseActionButton>
        </b-form-group>
      </b-form>
    </div>
  </section>
</template>
<script>
import { Supplier as SupplierModel } from "@/models/supplier";
import Supplier from "@/modules/general/supplier/components/Supplier.vue";

export default {
  name: "SupplierAdd",
  components: {
    Supplier,
  },
  data() {
    return {
      clicked: false,
      supplier: new SupplierModel({}),
    };
  },
  created() {
    this.$emit("updateLoader");
  },
  methods: {
    async onSubmit() {
      this.clicked = true;
      try {
        var supplier = await this.$store.dispatch(
          "supplierVuex/addSupplier",
          this.supplier
        );
        this.clicked = false;
        this.toast("Success", "Et pension selskab er nu oprettet", true);
        this.$router.push({ path: supplier.id + "/info" }); // Her er den originial fix når backend er rdy
      } catch (error) {
        this.clicked = false;
        console.log(error);
        this.toast("Error", "En fejl opstod: " + error.message, false);
      }
    },
  },
};
</script>
<style lang="scss">
</style>