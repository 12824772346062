<template>
	<div>
		<div class="row">
			<div class="col-12" v-if="!disabled">
				<label> {{ $t('global.search') }} </label>
				<InputText v-model="lookup" :disabled="disabled" :place="$t('global.searchPlaceholder')" />

				<BaseActionButton
					v-bind:clicked="clicked"
					v-bind:disabled="disabled || clicked"
					v-on:on-click="lookupCVR"
					type="button"
					id="signupButton"
					class="mt-1"
				>
					{{ $t('company.search') }}
				</BaseActionButton>
			</div>
			<div class="col-lg-6 col-12">
				<label>
					{{ $t('supplier.addEdit.cvr') }}
				</label>
				<InputNumber v-model="supplier.cvr" :disabled="disabled" :disableThousandSeparator="true" :required="true" />
			</div>

			<div class="col-lg-6 col-12">
				<label>
					{{ $t('supplier.addEdit.name') }}
				</label>
				<InputText v-model="supplier.name" :disabled="disabled" :required="true" />
			</div>

			<div class="col-lg-6 col-12">
				<label>
					{{ $t('supplier.addEdit.excelName') }}
				</label>
				<InputText v-model="supplier.excelName" :disabled="disabled" />
			</div>

			<div class="col-lg-6 col-12">
				<label>
					{{ $t('supplier.addEdit.website') }}
				</label>
				<InputText v-model="supplier.website" :disabled="disabled" />
			</div>

			<div class="col-lg-6 col-12">
				<label> Link til billede </label>
				<InputText v-model="supplier.imageUrl" :disabled="disabled" />
			</div>
		</div>
		<br />
	</div>
</template>
<script>
import InputText from '@/modules/global/atomComponents/InputText.vue'
import InputNumber from '@/modules/global/atomComponents/InputNumber.vue'

export default {
	name: 'Supplier',
	components: {
		InputText,
		InputNumber,
	},
	props: ['value', 'disabled'],
	data() {
		return {
			clicked: false,
			lookup: '',
		}
	},
	methods: {
		async lookupCVR() {
			try {
				this.clicked = true
				var result = await this.$store.dispatch('supplierVuex/lookupCVR', this.lookup)
				this.value.name = result.name
				this.value.cvr = result.vat
				this.clicked = false
			} catch (error) {
				console.log(error.message)
				this.toast('Noget gik galt', error.message, false)
				this.clicked = false
			}
		},
	},
	computed: {
		supplier: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
}
</script>

<style lang="scss"></style>
